import axios from 'axios';
import { setRequestDefaults, withRetry } from '../util/util.service';
import { notification } from '../notification/notification.service';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { config } from '../../configs/app.config';
import TagManager from 'react-gtm-module/dist/TagManager';

/*
join: Array of fields (string[])
all other properties according to CreateQueryParams type
 */
const prepareQueryParams = (queryParams) => {
  const { join, ...rest } = queryParams || {};

  return {
    limit: 9999, // set as default
    ...rest,
    ...(join && { join: join.map((field) => ({ field: field })) }),
  };
};

export const baseGetQuery = (
  path,
  queryParams,
  successMessageId,
  errorMessageId,
) => {
  const queryString = queryParams
    ? '?' + RequestQueryBuilder.create(prepareQueryParams(queryParams)).query()
    : '';

  return withRetry(() =>
    axios.get(`${config.apiBaseUrl}/${path}${queryString}`),
  )
    .then((res) => {
      console.debug(`GET /${path} result:`, res.data);
      if (successMessageId) notification('success', successMessageId);
      return res.data;
    })
    .catch((err) => {
      console.debug(`GET /${path} error:`, err.message);
      if (errorMessageId) notification('error', errorMessageId);
      throw err;
    });
};

export const basePostQuery = (
  path,
  data,
  queryParams,
  successMessageId,
  errorMessageId,
  skipRetry = false,
) => {
  const queryString = queryParams
    ? '?' + RequestQueryBuilder.create(prepareQueryParams(queryParams)).query()
    : '';
  const request = axios.post(
    `${config.apiBaseUrl}/${path}${queryString}`,
    data,
  );

  return (skipRetry ? request : withRetry(() => request))
    .then((res) => {
      console.debug(`POST /${path} result:`, res.data);
      if (successMessageId) notification('success', successMessageId);
      return res.data;
    })
    .catch((err) => {
      console.debug(`POST /${path} error:`, err.message);
      if (errorMessageId) notification('error', errorMessageId);
      throw err;
    });
};

export const basePatchQuery = (
  path,
  data,
  queryParams,
  successMessageId,
  errorMessageId,
) => {
  const queryString = queryParams
    ? '?' + RequestQueryBuilder.create(prepareQueryParams(queryParams)).query()
    : '';

  return withRetry(() =>
    axios.patch(`${config.apiBaseUrl}/${path}${queryString}`, data),
  )
    .then((res) => {
      console.debug(`PATCH /${path} result:`, res.data);
      if (successMessageId) notification('success', successMessageId);
      return res.data;
    })
    .catch((err) => {
      console.debug(`PATCH /${path} error:`, err.message);
      if (errorMessageId) notification('error', errorMessageId);
      throw err;
    });
};

export const baseDeleteQuery = (
  path,
  queryParams,
  successMessageId,
  errorMessageId,
) => {
  const queryString = queryParams
    ? '?' + RequestQueryBuilder.create(prepareQueryParams(queryParams)).query()
    : '';

  return withRetry(() =>
    axios.delete(`${config.apiBaseUrl}/${path}${queryString}`),
  )
    .then((res) => {
      console.debug(`DELETE /${path} result:`, res.data);
      if (successMessageId) notification('success', successMessageId);
      return res.data;
    })
    .catch((err) => {
      console.debug(`DELETE /${path} error:`, err.message);
      if (errorMessageId) notification('error', errorMessageId);
      throw err;
    });
};

export const getMenu = (menuId, queryParams = {}) => {
  return baseGetQuery(
    `menus/${menuId}`,
    { join: ['coverImage'], ...queryParams },
    null,
    'getMenuError',
  );
};

export const uploadSingleImage = (file) => {
  return basePostQuery(
    'media/image',
    { fileName: file.name },
    null,
    null,
    'imageUploadError',
  ).then((sbRes) => {
    // prepare file for upload
    const formData = new FormData();
    formData.append('file', file, file.name);

    return axios
      .post(`https://upload.imagedelivery.net/${sbRes.cfUploadId}`, formData, {
        // remove Authorization because this is not the SB Backend
        transformRequest: (data, headers) => {
          delete headers.common['Authorization'];

          // remove ngrok.io header if set (otherwise rejected because of CORS)
          if (headers.common['ngrok-skip-browser-warning']) {
            delete headers.common['ngrok-skip-browser-warning'];
          }

          return data;
        },
        timeout: 120 * 1000, // set timeout here to 120 sec
      })
      .then((cfRes) =>
        basePatchQuery(
          `media/image/${sbRes.id}`,
          { cfImageId: cfRes.data.result.id },
          null,
          null,
          'imageUploadError',
        ),
      )
      .catch((err) => {
        console.debug('Image Upload error:', err.message);
        notification('error', 'imageUploadError');
      });
  });
};

export const updateMenuEntryDisplayType = (
  id,
  displayType,
  onSuccessCallback,
) => {
  basePatchQuery(
    `menu-entries/${id}`,
    { displayType: displayType },
    null,
    'changesSaved',
    'changesSavedError',
  ).then(() => onSuccessCallback());
};

export const getMe = () => baseGetQuery('me');

export const getStore = (slug, isAdminMode = false) => {
  const path = 'stores' + (!isAdminMode ? `/${slug}/slug` : '');
  const request = baseGetQuery(path, {
    join: ['menu.coverImage', 'merchantProfile.billingAddress'],
  });
  return isAdminMode ? request.then(({ data }) => data[0]) : request;
};

export const getMenuEntries = (menuId, isAdminMode = false, id = null) => {
  const path =
    'menu-entries' +
    (!isAdminMode ? `/${menuId}/menu` : '') +
    (id ? `/${id}` : '');

  return baseGetQuery(path).then(({ data }) => {
    const menuEntries = data.map((menuEntryData) => {
      if (menuEntryData?.category) {
        return {
          ...menuEntryData,
          category: {
            ...menuEntryData?.category,
            displayType: data.find(
              (menuEntry) =>
                menuEntry.type === 'CategoryMenuEntry' &&
                menuEntry.categoryEntry.id === menuEntryData?.category.id,
            )?.displayType,
          },
        };
      } else {
        return menuEntryData;
      }
    });
    return menuEntries.filter(
      (menuEntry) =>
        isAdminMode ||
        (menuEntry.displayType !== 'hidden' &&
          (!menuEntry?.category ||
            (menuEntry?.category &&
              menuEntry.category.displayType !== 'hidden'))), // filter hidden items in non-admin mode
    );
  });
};

export const getItemCategories = (menuId, isAdminMode = false, id = null) => {
  const path =
    'item-categories' +
    (!isAdminMode ? `/${menuId}/menu` : '') +
    (id ? `/${id}` : '');

  return baseGetQuery(path, {
    join: ['parent', 'coverImage'],
  }).then(({ data }) => data);
};

export const getFoodAllergens = (id = null) => {
  const path = 'food-allergens' + (id ? `/${id}` : '');

  return baseGetQuery(path).then(({ data }) => data);
};

export const getFoodAdditives = (id = null) => {
  const path = 'food-additives' + (id ? `/${id}` : '');

  return baseGetQuery(path).then(({ data }) => data);
};

export const getFoodPreferences = (id = null) => {
  const path = 'food-preferences' + (id ? `/${id}` : '');

  return baseGetQuery(path).then(({ data }) => data);
};

export const getFoodIngredients = (id = null) => {
  const path = 'food-ingredients' + (id ? `/${id}` : '');

  return baseGetQuery(path).then(({ data }) => data);
};

export const getFoodItemAdditions = (id = null) => {
  const path = 'item-additions/food' + (id ? `/${id}` : '');

  return baseGetQuery(path).then(({ data }) => data);
};

export const getAdditionGroups = (id = null) => {
  const path = 'item-addition-groups' + (id ? `/${id}` : '');

  return baseGetQuery(path, { join: ['itemData'] }).then(({ data }) => data);
};

export const createGuest = (payload, setWebSocketToken, force = false) => {
  const guestToken = localStorage.getItem('guestToken');
  if (!guestToken || force) {
    return basePostQuery('guests', payload).then((guest) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'CreateGuestAccount',
        },
      });
      setRequestDefaults(guest?.token, true, setWebSocketToken);

      return guest;
    });
  } else {
    console.warn('guestToken already exists');
    return Promise.resolve(null);
  }
};

export const adoptGuest = () => {
  const guestToken = localStorage.getItem('guestToken');
  const adoptGuestMode = localStorage.getItem('adoptGuestMode');

  if (adoptGuestMode === 'yes' && guestToken) {
    return axios
      .get(`${config.apiBaseUrl}/me`, {
        // set Authorization because we need to access as guest
        transformRequest: (data, headers) => {
          headers.common['Authorization'] = guestToken;
          return data;
        },
      })
      .then(({ data }) => {
        return basePostQuery(`guests/adopt`, {
          token: guestToken,
        });
      })
      .then(() => {
        localStorage.removeItem('guestToken');
        localStorage.removeItem('adoptGuestMode');
        console.log('Successfully adopted guest!');
      })
      .catch((err) => {
        // giving up. Backup guestToken and delete adoptGuestMode
        localStorage.setItem('guestTokenBackup', guestToken);
        localStorage.removeItem('guestToken');
        localStorage.removeItem('adoptGuestMode');
        console.error('Could not adopt guest: ' + err?.message);
      });
  } else {
    console.log('No guestToken or adoptGuestMode found');
    return Promise.resolve();
  }
};

export const getOrders = (id = null, queryParams = {}, isAdminMode = false) => {
  const path = 'orders' + (id ? `/${id}` : '') + (isAdminMode ? '/store' : '');
  return baseGetQuery(path, queryParams).then(({ data }) => data);
};

export const getOrderStats = (queryParams = {}) => {
  const path = 'orders/store/stats';
  return baseGetQuery(path, queryParams);
};

export const getStoreTables = (queryParams = {}) => {
  const path = 'tables';
  return baseGetQuery(path, { ...queryParams }).then(({ data }) => data);
};

export const getStoreDiscountCodes = (queryParams = {}) => {
  const path = 'discount-codes';
  return baseGetQuery(path, { ...queryParams }).then(({ data }) => data);
};

export const getStorePrinters = (queryParams = {}) => {
  const path = 'printers/rego';
  return baseGetQuery(path, {
    limit: 9999,
    ...queryParams,
  }).then(({ data }) => data);
};

export const getStorePickupStations = (queryParams = {}) => {
  const path = 'pickup-stations';
  return baseGetQuery(path, {
    limit: 9999,
    ...queryParams,
  }).then(({ data }) => data);
};

export const getKiosks = (queryParams = {}) => {
  const path = 'kiosks';
  return baseGetQuery(path, {
    limit: 9999,
    ...queryParams,
  }).then(({ data }) => data);
};

export const getPaymentTerminals = (queryParams = {}) => {
  const path = 'payment-terminals';
  return baseGetQuery(path, {
    limit: 9999,
    ...queryParams,
  }).then(({ data }) => data);
};

export const getPaymentMethods = () => {
  return baseGetQuery('me/payment-methods');
};

export const setPaymentMethodDefault = (id) => {
  return basePostQuery('me/payment-methods/default', { paymentMethodId: id });
};

export const deletePaymentMethod = (id) => {
  return baseDeleteQuery('me/payment-methods/' + id);
};

export const getSpecificOrder = (id) => {
  const path = 'orders' + (id ? `/${id}` : '') + '/store';
  return baseGetQuery(path, { limit: 9999 });
};

export const getOrder = (id) => {
  const path = 'orders/' + id;
  return baseGetQuery(path, { limit: 9999 });
};

export const getStoreOrder = (id) => {
  const path = 'orders/' + id + '/store';
  return baseGetQuery(path);
};

export const getBillingAddresses = () => {
  return baseGetQuery('addresses/billing').then(({ data }) => data);
};

export const createBillingAddress = () => {
  return basePostQuery('addresses/billing');
};

export const updateBillingAddress = (id, payload) => {
  return basePatchQuery('addresses/billing/' + id, payload);
};

export const deleteBillingAddress = (id) => {
  return baseDeleteQuery('addresses/billing/' + id);
};

export const getStripeBalance = (id) => {
  return baseGetQuery('payment-processors/stripe/' + id + '/balance');
};

export const getDeliveryAddresses = () => {
  return baseGetQuery('addresses/delivery').then(({ data }) => data);
};

export const createDeliveryAddress = (payload) => {
  return basePostQuery('addresses/delivery', payload);
};

export const updateDeliveryAddress = (id, payload) => {
  return basePatchQuery('addresses/delivery/' + id, payload);
};

export const deleteDeliveryAddress = (id) => {
  return baseDeleteQuery('addresses/delivery/' + id);
};

export const getServiceTimes = (storeId) => {
  if (!storeId) {
    console.error('Store ID is not defined');
    return Promise.resolve(null);
  }

  return baseGetQuery('service-times/' + storeId + '/current-week').catch(
    (err) => {
      if (err.response && err.response.status === 404) {
        console.warn('Service times not found for store:', storeId);
        return [];
      }

      throw err;
    },
  );
};

export const createServiceTimes = (payload) => {
  return basePostQuery('service-times/bulk', { bulk: payload }).then(
    ({ data }) => data,
  );
};

export const updateMe = (payload) => {
  return basePatchQuery('me/', payload);
};

export const getCurrentServiceTime = (storeId) => {
  return baseGetQuery(`service-times/${storeId}/current`).catch((err) => {
    if (err.response && err.response.status === 404) {
      console.debug(`Current service time not found for store ${storeId}.`);
      return null;
    } else {
      throw err;
    }
  });
};

export const getNextStartServiceTime = (storeId) => {
  return baseGetQuery(`service-times/${storeId}/next-start`).catch((err) => {
    if (err.response && err.response.status === 404) {
      console.debug(`Next start service time not found for store ${storeId}.`);
      return null;
    } else {
      throw err;
    }
  });
};

export const updateTableCart = (tableId) => {
  return basePostQuery(
    `pos-integrations/${tableId}/update-table-cart`,
    null,
    null,
    null,
    null,
    true,
  );
};

export const updateCustomerProfile = (id, payload) => {
  return basePatchQuery('customer-profiles/' + id, payload);
};

export const getHelloTessSetup = (payload) => {
  return basePostQuery('pos-integrations/hellotess/setup', payload);
};

export const getPosIntegrations = () => {
  return baseGetQuery('pos-integrations');
};

export const getPosIntegrationItemDataMappings = (id) => {
  return baseGetQuery(`pos-integrations/${id}/item-data`);
};

export const getPosIntegrationItemAdditionMappings = (id) => {
  return baseGetQuery(`pos-integrations/${id}/item-additions`);
};

export const getPosIntegrationTableMappings = (id) => {
  if (id === undefined) {
    console.error('ID is undefined');
    return Promise.resolve(null);
  }

  return baseGetQuery(
    `pos-integrations/${id}/tables`,
  ) /* .catch((err) => {
    if (err.response && err.response.status === 400) {
      console.error('Bad request error:', err);
      return null;
    } else {
      throw err;
    }
  }) */;
};

export const getMergePortSetup = (payload) => {
  return basePostQuery('pos-integrations/mergePort/setup', payload);
};

export const getStores = (queryParams, showDeleted = false) => {
  let url = 'stores/admin';

  const params = new URLSearchParams({
    include_deleted: showDeleted ? 1 : 0,
    filter: 'deletedAt||$notnull',
    ...queryParams,
  }).toString();

  url += `?${params}`;

  return baseGetQuery(url).then((res) => res);
};

export const setOnBehalfOfStoreOwner = (selectedStore) => {
  return basePostQuery(`stores/admin/${selectedStore?.id}/on-behalf-of`).then(() => {
    const newTabUrl = window.location.origin + '/store/' + selectedStore?.slug;
    window.open(newTabUrl, '_blank');
  });
};

export const deleteOnBehalfOfStoreOwner = (
  selectedStore,
  redirect,
  fetchMe,
) => {
  return baseDeleteQuery(`stores/admin/${selectedStore?.id}/on-behalf-of`).then(
    () => {
      fetchMe();
      redirect();
    },
  );
};

export const getAllStoresStats = (queryParams = {}) => {
  const path = 'orders/store/stats/all';
  return baseGetQuery(path, queryParams);
};

export const getStoreOrders = (queryParams) => {
  const path = 'orders/store';
  return baseGetQuery(path, queryParams).then((res) => res);
};

export const getStoreOrdersCsv = (date) => {
  const path = `orders/store/${date}/csv`;
  return baseGetQuery(path);
};

export const handleChangePassword = () => {
  basePostQuery('me/reset-password', {
    redirectUrl: window.location.href,
  })
    .then((res) => (window.location.href = res.resetLink))
    .catch((error) => {
      notification('warning', 'passwordChangeError');
    });
};

export const createTableReservation = (payload) => {
  return basePostQuery('table-reservations', payload, null, null, null, true);
};

export const getConnectors = () => {
  return baseGetQuery('connectors');
};

export const getAreas = () => {
  const path = 'areas';
  return baseGetQuery(path).then(({ data }) => data);
};
